<template>
  <div class="page-branding">

    <div class="banner" style="background: gray;">

      <video autoplay muted loop playsinline v-if="catVid">
        <source :src="catVid" />Your browser does not support the video tag.
      </video>
    </div>

    <div v-if="getSubWorks">
      <div class="content works-contianer">
        <div @click="goBack" class="back-btn mb-3">
          <span class="mr-2 d-flex align-items-center">
            <i class="arrow left"></i>
          </span>
          <!-- <img src="@/assets/img/icon/caret-square-left-regular.svg" alt /> -->
          <span>{{ $t("global.back") }}</span>
        </div>

        <h1>{{ `${getCat.name} - ${subCatTitle}` }}</h1>

        <b-row>
          <b-col cols="12" md="4" v-for="(work, i) in getSubWorks.data" :key="work.work_id">
            <WorkCard :work="work" @click.native="(showModal = true), (selectedWork = i)" />
          </b-col>
        </b-row>

        <div v-if="getSubWorks && !getSubWorks.data.length">
          <h2>0 Work Found</h2>
        </div>

        <div class="text-center mt-5 d-flex justify-content-center" @click="loadMore()"
          v-if="getSubWorks.next_page_url">
          <div class="load-more">{{ $t("global.loadMore") }}</div>
        </div>
      </div>
    </div>

    <transition name="modal">
      <WorkSwiperModal v-if="showModal" :works="getSubWorks.data" :selectedWork="selectedWork" />
    </transition>
  </div>
</template>

<script>
import { bus } from '@/main'
import axios from 'axios'
// import Vue from 'vue'
// import { nextTick } from 'vue'
export default {
  name: 'BrandingSub',
  metaInfo() {
    return {
      title: () => {
        const title = this.$route.params.subCat
        return this.makeTitle(title)
      }
    }
  },
  data() {
    return {
      param: {
        params: {
          lang: null,
          per_page: 12
        }
      },
      showModal: false
    }
  },
  computed: {
    allCats() {
      return this.$t('categories').data
    },
    getCat() {
      return this.allCats.find(el => el.slug === this.$route.params.cat)
    },
    catVid() {
      let res = ''
      try {
        res = require(`@/assets/video/${this.getCat.id}.mp4`)
      } catch (error) {
        console.log(error)
      }
      return res
    },
    getSubWorks() {
      return this.$t('works').subWorks.find(el => el.slug === this.$route.params.subCat)
    },
    subCatTitle() {
      if (this.$t('categories').subCats.length) {
        return this.$t('categories').subCats.find(({ slug }) => slug === this.getSubWorks.slug).name
      }
      return null
    }
  },
  methods: {
    makeTitle(slug) {
      var words = slug.split('-');

      for (var i = 0; i < words.length; i++) {
        var word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }

      return words.join(' ');
    },
    async loadMore() {
      // console.log(this.$t('works.data')[1].next_page_url)
      const url = this.getSubWorks.next_page_url
      try {
        const res = await axios.get(url, this.param)
        // if (!res.data[1].next_page_url) return
        this.getSubWorks.next_page_url = res.data[1].next_page_url
        res.data[0].forEach(newWork => {
          this.getSubWorks.data.push(newWork)
        })
      } catch (erorr) {
        console.log(erorr)
      }
    },
    getWorks() {
      this.param.params.lang = this.$i18n.locale
      this.param.params.subCat = this.$route.params.subCat
      if (!this.$t('works').subWorks.some(el => el.slug === this.$route.params.subCat)) {
        this.$store.dispatch('fetchWorks', this.param)
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  components: {
    WorkCard: () => import('./partials/WorkCard.vue'),
    WorkSwiperModal: () => import('./partials/SwiperModal.vue')

  },
  mounted() {
    this.getWorks()
    bus.$on('updateWorkLocate', this.getWorks)
    bus.$on('closeModal', () => { this.showModal = false })
    // console.log(this.getSubWorks)
  }
}
</script>

<style lang='scss'>
.page-branding {
  padding: 150px 0;

  h1 {
    font-size: 1.5rem;
  }

  // .loadmore-btn{
  //   display: none;
  // }
  .banner {
    height: 250px;
    margin-bottom: 80px;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .works-contianer {
    [class*="col-"] {
      padding: 5px;
      padding-bottom: 0;
      margin-bottom: 5px;
      overflow: hidden;
    }
  }

  @media (min-width: 768px) {
    .banner {
      height: 600px;
    }

    .page-branding {
      h1 {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
